import type { ZudokuConfig } from "zudoku";

const config: ZudokuConfig = {
  topNavigation: [
    { id: "docs", label: "Intro" },
    { id: "api-v1", label: "API Reference V1" },
  ],
  page: {
    logo: {
      src: {
        light: 'https://vtex.com/_next/static/media/vtex-logo.80485bcf.svg',
        dark: 'https://vtex.com/_next/static/media/vtex-logo.80485bcf.svg'
      },
      alt: 'VTEX Logo',
      width: '130px'
    }
  },
  metadata: {
    favicon: '/favicon.ico'
  },
  sidebar: {
    docs: [
      {
        type: "category",
        label: "Overview",
        items: ["introduction"],
      },
    ],
  },
  redirects: [
    { from: "/", to: "/docs/introduction" },
    { from: "/docs", to: "/docs/introduction" },
  ],
  apis: {
    type: "file",
    input: "./openapi/pnp_docs.yaml",
    navigationId: "api-v1",
  },
  docs: {
    files: "/pages/**/*.{md,mdx}",
  }
};

export default config;
